import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Star from '../../static/icons/star-active.svg';
import { colors } from '../styles/colors';
import { devices } from '../styles/breakpoints';

const LexiconLinkItem = styled.li`
  border-bottom: 2px solid ${colors.blue};
  position: relative;

  svg {
    position: absolute;
    right: 20px;
  }
`;

const LexiconLink = styled(Link)`
  padding: 20px 0;
  color: ${colors.black};
  display: block;
  font-family: Genera-Regular;
  background: transparent;
  transition: background 200ms ease-in-out;

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-one']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-one']};
  }

  &:active {
    background: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const LexiconListLink = ({ link, title }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    const isAlreadyinStorage = () => {
      // check if localStorage even exists, if not create it, else use it
      if (localStorage.getItem('Merkliste') === null) {
        return false;
      } else {
        const arrayString = localStorage.getItem('Merkliste');
        const array = JSON.parse(arrayString);
        return array.some((item) => item.slug === link);
      }
    };
    setIsBookmarked(isAlreadyinStorage());
  }, [link]);

  return (
    <LexiconLinkItem key={title}>
      <LexiconLink to={link}>
        {title}
        {isBookmarked ? <Star width="12" height="12" aria-hidden="true" /> : ''}
      </LexiconLink>
    </LexiconLinkItem>
  );
};

export default LexiconListLink;
