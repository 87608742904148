import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from '@3pc/layout-components-react';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/Footer';
import { sortItems } from '../utils';
import ResponsiveImage from '../base/ResponsiveImage';
import LexiconListLink from '../base/LexiconListLink';

export const query = graphql`
  query($slug: String!) {
    allFile(filter: { childMdx: { frontmatter: { type: { eq: $slug } } } }) {
      nodes {
        childMdx {
          frontmatter {
            slug
            title
          }
        }
      }
    }
    image: file(relativePath: { eq: "12.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    mobileImage: file(relativePath: { eq: "12.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
  }
`;

const LexiconLinkTitle = styled.h3`
  color: ${colors.blue};
  padding: 10px 0;
  border-bottom: 2px solid ${colors.blue};
`;

const LexiconPage = (data) => {
  const firstLetters = [];
  const nodes = data.data.allFile.nodes;
  nodes.forEach((el) => {
    const title = el.childMdx.frontmatter.title;
    const firstLetterOfTitle = title[0];
    firstLetters.push(firstLetterOfTitle);
  });
  const uniqueFirstLetters = [...new Set(firstLetters)].sort((a, b) =>
    a.localeCompare(b)
  );

  /* Define image sources */
  const defaultImage = data.data.image.childImageSharp.gatsbyImageData;
  const mobileImage = data.data.mobileImage.childImageSharp.gatsbyImageData;
  const altTag =
    'Farbfotografie von Schülern und Schülerinnen in einem Plenarsaal während eines Workshops zur Verfassungsänderung im Jahr 2018';

  return (
    <>
      <PageHeader headerTitle="Lexikon" pageType={'sublist-page'} />

      <Layout>
        <Box
          position="relative"
          aria-labelledby="header-title"
          mt={headerHeight.headerMobile}
        >
          <ResponsiveImage
            defaultImage={defaultImage}
            mobileImage={mobileImage}
            altTag={altTag}
          />
        </Box>
        <Box
          as="nav"
          id="headerNavTitle"
          pl="20px"
          pb={footerHeight.footerMobile}
        >
          <Box as="ol" margin={['12px 0 0 0', '17px 15% 0 15%']}>
            {uniqueFirstLetters.map((letter) => (
              <Box as="li" key={letter} pb="30px">
                <LexiconLinkTitle>{letter}</LexiconLinkTitle>
                <Box as="ol">
                  {nodes
                    .sort((a, b) => {
                      return sortItems(
                        a.childMdx.frontmatter.title,
                        b.childMdx.frontmatter.title
                      );
                    })
                    .map((file) =>
                      file.childMdx.frontmatter.title[0] === letter ? (
                        <LexiconListLink
                          title={file.childMdx.frontmatter.title}
                          link={`/${file.childMdx.frontmatter.slug}`}
                          key={file.childMdx.frontmatter.title}
                        />
                      ) : (
                        ''
                      )
                    )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Layout>

      <Footer />
    </>
  );
};

export default LexiconPage;
